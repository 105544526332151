.layout-two {
  height: 100vh;
  position: relative;
}
.layout-two .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  padding: 5px 40px;
  height: 54px;
  padding-top: 8px;
  padding-left: 42px;
  position: fixed;
  left: 0;
  right: 0;
}

.layout-two .header .left {
  display: flex;
  align-items: center;
}

.layout-two .header .left img {
  max-width: 40px;
}

.header-logo-text {
  padding-left: 20px;
}

.layout-two .header .center {
  width: 600px;
}
.layout-two .header .center .search-input .MuiInputBase-root:focus .MuiOutlinedInput-notchedOutline, .layout-two .header .center .search-input .MuiInputBase-root:focus-within .MuiOutlinedInput-notchedOutline {
  border-color: #173161;
}
.layout-two .header .center .search-input .MuiInputBase-root:hover {
  border-radius: 9px;
}
.layout-two .header .center .search-input .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #173161;
}
.layout-two .header .center .search-input .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 10px 9px 0;
  max-height: 38px;
  font-size: 14px;
  color: #212121;
}
.layout-two .header .center .search-input .MuiInputBase-root .MuiInputBase-input::-moz-placeholder {
  color: #212121;
  font-weight: 400;
  opacity: 0.5;
}
.layout-two .header .center .search-input .MuiInputBase-root .MuiInputBase-input::placeholder {
  color: #212121;
  font-weight: 400;
  opacity: 0.5;
}
.layout-two .header .center .search-input .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-radius: 9px;
  border: 1.5px solid rgba(33, 33, 33, 0.1);
}
.layout-two .header .center .search-input .MuiInputBase-root .MuiOutlinedInput-notchedOutline:hover {
  border: 1px solid #173161;
}
.layout-two .header .right {
  display: flex;
  align-items: center;
  gap: 26px;
  list-style: none;
}

.layout-two .header .right ul {
  display: flex;
  align-items: center;
  gap: 26px;
  list-style: none;
}
.layout-two .header .right ul li {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  color: rgb(33, 33, 33);
}
.layout-two .header .right ul li .icon {
  width: 22px;
  height: 22px;
  color: rgba(33, 33, 33, 0.8);
}
.layout-two .header .right ul li .icon:hover {
  color: #173161;
}
.layout-two .header .right ul li .selected {
  color: #173161;
}
.layout-two .header .right ul li .avatar {
  width: 25px;
  height: 25px;
  font-size: 15px;
}
.layout-two .page {
  display: flex;
  align-items: center;
  padding-top: 54px;
}
.layout-two .page .page-right {
  height: calc(100vh - 54px);
  overflow: scroll;
  width: 100%;
  padding: 20px;
}

.new-side-bar {
  position: relative;
  width: 250px;
  padding-right: 1px;
  border-right: none;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 33%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 1.4px 10px;
  background-repeat: repeat-y;
  transition: width 0.3s ease; /* Add transition property for smooth width changes */
}
.new-side-bar .expended-view {
  height: calc(100vh - 54px);
  overflow-y: scroll;
}
.new-side-bar .expended-view .item {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 20px;
}
.new-side-bar .expended-view .item .top-head {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 40px;
  height: 55px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  cursor: pointer;
}
.new-side-bar .expended-view .item .top-head .icons {
  width: 24px;
  height: 24px;
  color: rgba(33, 33, 33, 0.6);
}
.new-side-bar .expended-view .item .top-head a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  cursor: pointer;
}
.new-side-bar .expended-view .item .top-head:hover {
  background: rgba(0, 52, 187, 0.1);
  color: #173161;
}
.new-side-bar .expended-view .item .top-head:hover .icons {
  color: #173161;
}
.new-side-bar .expended-view .item .top-head:hover a {
  color: #173161;
}
.new-side-bar .expended-view .item .active {
  border-right: 4px solid #173161;
  background: rgba(0, 52, 187, 0.1);
  color: #173161;
}
.new-side-bar .expended-view .item .active .icons {
  color: #173161;
}
.new-side-bar .expended-view .item .active a {
  color: #173161;
}
.new-side-bar .expended-view .item .menu {
  box-shadow: none !important;
  border: none !important;
  margin-bottom: 0px;
}
.new-side-bar .expended-view .item .menu ::before {
  height: 0px !important;
}
.new-side-bar .expended-view .item .menu .list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px;
}
.new-side-bar .expended-view .item .menu .list .a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: #212121;
  padding: 8px 40px;
  padding-left: 77px;
  cursor: pointer;
}
.new-side-bar .expended-view .item .menu .list .a:hover {
  padding: 8px 38px;
  padding-left: 77px;
  background: rgba(0, 52, 187, 0.1);
  color: #173161;
}
.new-side-bar .expended-view .item .menu .list .active1 {
  border-right: 4px solid #173161;
  background: rgba(0, 52, 187, 0.1);
  color: #173161;
}
.new-side-bar .expended-view .item .menu .MuiButtonBase-root {
  padding-left: 40px;
  padding-right: 10px;
}
.new-side-bar .expended-view .item .menu .MuiButtonBase-root .MuiAccordionSummary-content .title-head {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 180px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  cursor: pointer;
}
.new-side-bar .expended-view .item .menu .MuiButtonBase-root .MuiAccordionSummary-content .title-head .icons {
  width: 24px;
  height: 24px;
  color: rgba(33, 33, 33, 0.6);
}
.new-side-bar .expended-view .item .menu .MuiButtonBase-root:hover {
  background: rgba(0, 52, 187, 0.1);
}
.new-side-bar .expended-view .item .menu .MuiButtonBase-root:hover .MuiAccordionSummary-content .title-head {
  color: #173161;
}
.new-side-bar .expended-view .item .menu .MuiButtonBase-root:hover .MuiAccordionSummary-content .title-head .icons {
  color: #173161;
}
.new-side-bar .expended-view .item .menu .Mui-expanded {
  color: #173161;
  align-items: flex-start;
  min-height: 30px;
  gap: 20px;
}
.new-side-bar .expended-view .item .menu .Mui-expanded .MuiAccordionSummary-content .title-head {
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: #173161;
  cursor: pointer;
}
.new-side-bar .expended-view .item .menu .Mui-expanded .MuiAccordionSummary-content .title-head .icons {
  width: 24px;
  height: 24px;
  color: #173161;
}
.new-side-bar .expended-view .item .menu:before {
  height: 0px !important;
}

.dashboard .table {
  
}
.dashboard .table .button {
  text-align: right;
  margin-bottom: 30px;
}
.dashboard .table .button button {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 7px;
  min-width: 120px;
}

.user-create {
  padding: 30px;
}
.user-create .row {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
}
.user-create .row .left {
  width: 45%;
}
.user-create .row .left p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 3px;
}
.user-create .row .left .text-box {
  width: 100%;
}